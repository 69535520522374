import { forwardRef, ForwardedRef } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

// React phone number input uses libphonenumber-js under the hood
// Importing country code and E146Number for types
import { CountryCode, E164Number } from 'libphonenumber-js/core'

import styles from './TelephoneInput.module.css'
import controlStyles from '../FormControls/FormControls.module.css'
import 'react-phone-number-input/style.css'

type InputProps = React.ComponentProps<'input'>

type TelephoneInputProps = InputProps & {
  defaultCountry?: CountryCode
  setValue: (value: E164Number) => void
  ref: ForwardedRef<HTMLInputElement>
}

const inputComponent = forwardRef<HTMLInputElement, InputProps>((inputProps, ref) => (
  <input ref={ref} {...inputProps} className={controlStyles.fieldInput} />
))
inputComponent.displayName = 'input'

const TelephoneInput = forwardRef<HTMLInputElement, TelephoneInputProps>(
  (props: TelephoneInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, value, disabled, setValue, defaultCountry, placeholder } = props

    const possibleNumberValue = (value as E164Number) ?? ''

    return (
      <PhoneInput
        // NOTE: Disabling linting for now as passing ref on the PhoneInput library component
        // spouts off typing error for ForwardedRef
        // eslint-disable-next-line
        ref={ref as any}
        className={styles.phoneInputContainer}
        inputComponent={inputComponent}
        id={name}
        name={name}
        defaultCountry={defaultCountry ?? 'AU'}
        placeholder={placeholder}
        value={possibleNumberValue}
        onChange={setValue}
        disabled={disabled}
        error={isValidPhoneNumber(possibleNumberValue) ? undefined : 'Invalid phone number'}
      />
    )
  }
)
TelephoneInput.displayName = 'TelephoneInput'

export default TelephoneInput
